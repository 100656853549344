/**
 * Global Animation Handler
 * 
 * This script provides animation functionality for all elements with animation classes.
 * It uses Intersection Observer to detect when elements enter the viewport
 * and applies the appropriate animation classes.
 */

document.addEventListener('DOMContentLoaded', function() {
    // IntersectionObserver setup
    const observerOptions = {
        root: null, // use viewport as root
        rootMargin: '0px',
        threshold: 0.15 // trigger when 15% of the element is visible
    };

    // Handle animation when element comes into view
    const handleAnimatedElement = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Add in-view class to trigger the animation
                entry.target.classList.add('in-view');
                
                // If it's a one-time animation, stop observing
                if (!entry.target.hasAttribute('data-animation-repeat')) {
                    observer.unobserve(entry.target);
                }
            } else if (entry.target.hasAttribute('data-animation-repeat')) {
                // If element has left viewport and has repeat attribute,
                // remove the in-view class to reset animation
                entry.target.classList.remove('in-view');
            }
        });
    };

    // Create the observer
    const animationObserver = new IntersectionObserver(handleAnimatedElement, observerOptions);

    // Find all elements with animation classes
    const animatedElements = document.querySelectorAll('[class*="animate-"]');
    
    // Observe each animated element
    animatedElements.forEach(element => {
        // Set index for staggered animations if element is part of a group
        const animationGroup = element.getAttribute('data-animation-group');
        if (animationGroup) {
            const groupElements = document.querySelectorAll(`[data-animation-group="${animationGroup}"]`);
            groupElements.forEach((el, index) => {
                el.style.setProperty('--animation-index', index);
            });
        }
        
        // Start observing this element
        animationObserver.observe(element);
    });

    /**
     * Add animation class to element
     * @param {HTMLElement} element - The element to animate
     * @param {string} animationName - Animation name from _animations.scss
     * @param {Object} options - Additional animation options
     */
    window.animateElement = function(element, animationName, options = {}) {
        // Default options
        const defaults = {
            delay: 0,
            duration: 'normal',
            repeat: false,
            group: null
        };
        
        // Merge options
        const settings = {...defaults, ...options};
        
        // Add animation class
        element.classList.add(`animate-${animationName}`);
        
        // Add duration class if specified
        if (settings.duration !== 'normal') {
            element.classList.add(`animate-duration-${settings.duration}`);
        }
        
        // Add delay class if specified
        if (settings.delay > 0) {
            const delayIndex = Math.min(Math.floor(settings.delay * 10), 10);
            element.classList.add(`animate-delay-${delayIndex}`);
        }
        
        // Set repeat attribute if needed
        if (settings.repeat) {
            element.setAttribute('data-animation-repeat', true);
        }
        
        // Set group attribute if specified
        if (settings.group) {
            element.setAttribute('data-animation-group', settings.group);
        }
        
        // Make sure the element is being observed
        animationObserver.observe(element);
        
        return element;
    };
});